<template>
  <main id="content" role="main">
    <section class="visual_intro">
      <div class="visual_wall">
      </div>
      <div class="intro_title">
        <strong class="title">K-STARTUP TO THE WORLD</strong>
      </div>
    </section>
    <section class="report_group">
      <section class="report_box">
        <div class="box_title">
          <h2 class="title">{{ $t('content.accelerating.Common.txt39') }}</h2>
          <p class="description">
            {{ $t('content.accelerating.Common.txt32') }}<br />
            {{ $t('content.accelerating.Common.txt33') }}<br />
            {{ $t('content.accelerating.Common.txt34') }}
          </p>
        </div>
        <div class="contest-sort">
          <div class="order_flag">
            <button role="button" aria-haspopup="listbox" v-text="rangeText"
                :aria-expanded="rangeShow" class="current" @click="rangeCol" style="color:black"></button>
              <ul class="flag_list" role="listbox">
                  <li class="flag_item" role="presentation">
                      <button class="flag" role="option" @click="rangeCol" style="color:black">
                        {{ $t('content.news.list.sort.dates') }}
                      </button>
                  </li>
                  <li class="flag_item" role="presentation">
                      <button class="flag" role="option" @click="rangeCol" style="color:black">
                        {{ $t('content.news.list.sort.registrated') }}
                      </button>
                  </li>
              </ul>
          </div>
        </div>
        <div class="contest-list">
          <ul>
            <template v-if="dataList && dataList.length > 0">
              <li v-for="contest in dataList" @click.prevent="goDetail(contest.id)">
                <div class="thumb">
                  <span :style="`background-image: url(${contest.thumbnailFileUrl || example});`"></span>
                </div>
                <div class="info">
                  <span class="title">{{ contest. title }}</span>
                  <span v-if="contest.state === 1" class="status type-ing">{{ $t('content.accelerating.Common.txt36') }}</span>
                  <span v-else-if="contest.state === 2" class="status type-ready">{{ $t('content.accelerating.Common.txt35', [ contest.startDate ]) }}</span>
                  <span v-else-if="contest.state === 3" class="status type-finish">{{ $t('content.accelerating.Common.txt37') }}</span>
                  <span v-else-if="contest.state === 0" class="status">{{ $t('content.accelerating.Common.txt38') }}</span>
                  <span v-if="!contest.isAlwaysRecruitment" class="date">
                    <template v-if="contest.state !== 2">
                      {{ $t('content.accelerating.Common.txt20') }} {{ contest.startDate }} ~ {{ contest.endDate }}
                    </template>
                  </span>
                  <span v-else class="date"></span>
                </div>
              </li>
            </template>
            <template v-else>
              <li>
                <div class="info">
                  <span class="title" style="text-align: center; width: 100%; padding: 50px;">{{ $t('content.accelerating.Common.txt40') }}</span>
                </div>
              </li>  
            </template>
          </ul>
        </div>
        <!-- <div class="btn-wrap">
          <button button class="btn-more" v-show="moreVisible" @click="getList()">
            <span class="circle">
              <span class="icon-plus">+</span>
            </span>
            <em class="btn-text">{{$t('content.news.list.more')}}</em>
          </button>
        </div> -->
        <Pagination ref="Pagination" v-if="dataList && dataList.length > 0"/>
      </section>
    </section>
  </main>
</template>

<script>

import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import Pagination from '@/components/base/Pagination.vue';
import { APIs } from '@/common/portalApi';

export default {
  name: 'MobileContestList',
  mixins: [
    mixinHelperUtils,
  ],
  components: {
    Pagination
  },
  metaInfo() {
    return {
      meta: this.$route.meta.metaInfo
    };
  },
  data() {
    return {
      example : 'https://staginggangnam.dreamplus.asia/assets/images/company/all_noimg.png',
      dataList: [],
      search: {
        page: 1,
        size: 12,
        order: 'desc',
        data: {
          isShow: true
        }
      },
      totalCount: 0,
      count: 0,
      rangeText: this.$t('content.news.list.sort.dates'),
      rangeShow: false,
      moreVisible: true
    };
  },
  mounted(){
    this.getList();
    const elVisual = document.getElementsByClassName('visual_intro');
    setTimeout(() => {
      elVisual[0].className += ' ani_intro';
    }, 100);
  },
  methods: {
    goDetail(id) {
      this.$router.push({ path: `/contest/detail/${id}` })
    },
    init() {
      this.dataList = [];
      this.search.page = 1;
      this.getList();
    },
    /**
    * 순서 정렬
    */
    rangeCol(e) {
      this.rangeShow = !this.rangeShow;
      if (e.target.classList.value !== 'flag') return;
      if (e.target.innerHTML.trim() === this.$t('content.news.list.sort.dates')) {
        this.rangeText = e.target.innerHTML;
        this.search.order = 'desc';
      } else if (e.target.innerHTML.trim() === this.$t('content.news.list.sort.registrated')) {
        this.range = [false, true, false];
        this.rangeText = e.target.innerHTML;
        this.search.order = 'asc';
      } else if (e.target.innerHTML.trim() === this.$t('content.news.list.sort.popularity')) {
        this.range = [false, true, false];
        this.rangeText = e.target.innerHTML;
        this.search.order = 'popularity';
      }
      this.init();
    },
    getList (page) {
      if (!page) {
        page = 1
      }
      this.search.page = page;
      this.portalApiClient({
        url: APIs.CONTEST_LIST,
        method: 'post',
        data: this.search
      }).then(({ data }) => {
        if (data.result) {
          // this.dataList = this.dataList.concat(data.list);
          this.dataList = data.list
          this.count = data.count;
          this.totalCount = data.totalCount;

          this.$refs.Pagination.loadPage(this.count, this.search.size, this.search.page);
          // // 더보기 버튼 활성화
          // console.log(this.dataList.length, data.count, data.totalCount)
          // if (this.dataList.length >= this.count) {
          //   this.moreVisible = false;
          // } else {
          //   this.moreVisible = true;
          //   this.search.page += 1
          // }
        } else {
          this.showAlert(data.message);
        }
      });
    }
  }
};
</script>

<style>
.contest-list > ul > li {
  cursor: pointer;
}
.order_flag .flag_list {
  right: 0.4rem !important;
}
</style>
